import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

const VanillaPage = () => (
	<Layout headerColor='yellow'>
      <SEO title="Vanilla" />

      <h1>Vanilla Page Coming Soon</h1>
  	</Layout>
)

export default VanillaPage;